import { getApiWrapper, postApiWrapper } from "./token-wrapper-function.js";

// Login - get user token
export const complaints = async () => {
    const result = await getApiWrapper(`/api/complains`);
    return result;
}

export const updateComplaintsStatus = async (rowId, status) => {
  const result = await postApiWrapper(`/api/complains/status/${rowId}`, { "status": status });
  return result;
}

export const complaintsByDate = async (startDate, endDate) => {
  const result = await getApiWrapper(`/api/complains?start_date=${startDate}&end_date=${endDate}`);
  return result;
}

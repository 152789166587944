import React, { useState, useEffect , forwardRef, useImperativeHandle } from "react";
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

const dateRange = forwardRef(({ onChange, isOpen }, ref) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ranges, setRanges] = useState([
        {
            startDate: null,
            endDate: null,
        }
    ]);


    const handleDateRangePicker = (ranges: { range1: any }) => {
        console.log('ranges', ranges);
        const { range1 } = ranges;
        const changedStartDate = moment(range1.startDate).format("YYYY-MM-DD")
        const changedEndDate = moment(range1.endDate).format("YYYY-MM-DD")
        setRanges([{ startDate: range1.startDate, endDate: range1.endDate }]);
        setStartDate(changedStartDate);
        setEndDate(changedEndDate);
        onChange(range1);
    };

    useImperativeHandle(ref, () => ({
        onReset(){
            setStartDate('')
            setEndDate('')
            setRanges(
                [
                    {
                        startDate: null,
                        endDate: null,
                    }
                ]
            )
        }
    }))

    

    return (
        <>
            <p>{startDate ? `Start Date:  ${startDate} | End Date:  ${endDate}` : 'Date Range is not selected yet!'} </p>
            {isOpen ? <DateRangePicker
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                direction="horizontal"
                ranges={ranges}
                onChange={handleDateRangePicker}
            /> : ""}
        </>
    )
})

export default dateRange;


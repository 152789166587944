import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { Status } from '@paljs/ui/types';

import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';

import { complaints, complaintsByDate, updateComplaintsStatus } from '../../actions/complaintsAction';

import Auth, { Group } from '../../components/Auth';
// import Socials from '../../components/Auth/Socials';
import SEO from '../../components/SEO';
import moment from 'moment';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import styles from "./complaints.css";

import DataTable from 'react-data-table-component';
//import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import DateRangePicker from '../../components/date/date-range';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../loader';

import { SUCCESS, ERROR, API_HOST } from '../../constant/string';

const STATUS_PENDING = 'PENDING';
const STATUS_RESOLVED = 'RESOLVED';

export default function index() {
  const [isLoading, showLoader] = useState(true);
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [originalRows, setOriginalRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('All');
  const [ranges, setRanges] = useState([
    {
      startDate: null,
      endDate: null,
    },
  ]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterParam, setFilterParam] = useState(['All']);

  const previousDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');

  const tableColumns = [
    {
      name: 'S.No.',
      sortable: false,
      grow: 0.2,
      cell: (row, index, column, id) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      name: 'LoggedIn Node ID',
      selector: (row) => row.logged_in_node_id,
      sortable: true,
    },
    {
      name: 'Selected Node ID',
      selector: (row) => row.selected_node_id,
      sortable: true,
      cell: (row) => (
        <a
          href={`/family-tree?idOfUser=${row.selected_node_id}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {row.selected_node_id}
        </a>
      ),
    },
    {
      name: 'Selected Node Name',
      selector: (row) => row.selected_node_name,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => row?.raisedAt,
      sortFunction: (a, b) => handleNullValues(a, b),
      cell: (row) => <>{moment(row?.raisedAt).format('YYYY-MM-DD')}</>,
    },
    {
      name: 'Message',
      selector: (row) => row.message,
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      name: 'Complaint Status',
      button: true,
      left: true,
      cell: (row: { status: string; selected_node_name: any; _id: any }) => (
        //   console.log('row', row)
        // { //Check if message failed
        <Button
          id={`button-${row._id}`}
          shape={'Round'}
          size={'Tiny'}
          status={row.status === STATUS_PENDING ? 'Warning' : 'Success'}
          onClick={() => {
            if (
              window.confirm(
                `Are you sure you want to mark ${row.selected_node_name}'s query as ${
                  row.status === 'PENDING' ? 'resolved' : 'unresolved'
                }?`,
              )
            )
              queryResolve(row._id, row.status === STATUS_PENDING ? STATUS_RESOLVED : STATUS_PENDING);
          }}
        >
          {row.status === STATUS_PENDING ? 'Mark Resolved' : 'Mark Pending'}
        </Button>
      ),
    },
  ];

  // getting complaints list
  useEffect(() => {
    // getComplaintList();
    dateRangeFilterData(previousDate, currentDate);
  }, []);

  // const getComplaintList = async () => {
  //   const response = await complaints()
  //   response.data.complains.sort((a,b)=>handleNullValues(a,b))
  //   let data = response.data.complains.map((currentValue: { SNO: any }, Index: number) => {
  //     currentValue.SNO = Index + 1;
  //     return currentValue;
  //   });
  //   setOriginalRows(data);
  //   setRows(data);
  //   toast.success('All Data have been fetched successfuly');
  //   showLoader(false);
  //   console.log('loading data of complaints', data);

  // }

  //sorting function to deal with null values
  const handleNullValues = (a, b) => {
    if (a.raisedAt == null && b.raisedAt == null) {
      return 0;
    }
    if (a.raisedAt == null) {
      return 1;
    }
    if (b.raisedAt == null) {
      return -1;
    }
    if (a.raisedAt > b.raisedAt) {
      return -1;
    } else return 1;
  };

  // update status

  const queryResolve = async (rowId: any, status: string) => {
    try {
      const response = await updateComplaintsStatus(rowId, status);
      const updatedData = originalRows.map((x) => {
        if (x['_id'] === response.data.id) {
          x = response.data.complain;
        }
        return x;
      });
      setOriginalRows(updatedData);
      filterRows(filter, updatedData);
    } catch (error) {
      setError('Something went wrong!');
    }
  };

  const handleInputChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      do_search();
    }
  };

  const do_search = () => {
    const value = search.trim();
    const temp_rows = originalRows.filter((e) => JSON.stringify(e).indexOf(value) >= 0);
    setRows(temp_rows);
  };

  // filtering complaints acc to date
  const dateRangeFilterData = async (startDate: any, endDate: any) => {
    const response = await complaintsByDate(startDate, endDate);
    response?.data?.complains?.sort((a, b) => handleNullValues(a, b));
    let data = response?.data?.complains?.map((currentValue: { SNO: any }, Index: number) => {
      currentValue.SNO = Index + 1;
      return currentValue;
    });
    setOriginalRows(data);
    setRows(data);
    showLoader(false);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const dateRangeChange = (range) => {
    const changedStartDate = moment(range.startDate).format('YYYY-MM-DD');
    const changedEndDate = moment(range.endDate).format('YYYY-MM-DD');
    range.startDate !== range.endDate ? setIsOpen(!isOpen) : '';
    dateRangeFilterData(changedStartDate, changedEndDate);
  };
  const filterRows = (value, temp_rows = originalRows) => {
    console.log('value to filter', value);
    const filtered = temp_rows.filter((row) => row.status === value);
    console.log('filtered rows', filtered);
    if (value !== 'All') {
      setRows(filtered);
    } else {
      setRows(temp_rows);
    }
  };

  const onDateRangeToggle = () => {
    setIsOpen(!isOpen);
  };

  const tableDiv = (
    <>
      <SEO title="Login" />
      <div>
        <div>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <Button
                  fullWidth
                  appearance="outline"
                  shape={'Round'}
                  size={'Small'}
                  Status={'Info'}
                  onClick={onDateRangeToggle}
                >
                  Select Date Range
                </Button>
              </Col>
              <select
                // onChange={(event) => {
                //   setFilterParam(event.target.value);
                //   console.log('from select ', event.target.value);
                //   const filter = originalRows.filter((eh) => eh.status === event.target.value);
                //   if (event.target.value !== 'All') {
                //     setRows(filter);
                //   } else {
                //     setRows(originalRows);
                //   }
                // }}
                onChange={(event) => {
                  setFilter(event.target.value);
                  filterRows(event.target.value);
                }}
              >
                <option value="All">Filter By Complaint Status</option>
                <option value={STATUS_PENDING}>Pending</option>
                <option value={STATUS_RESOLVED}>Resolved</option>
              </select>
            </Col>
            <Col>
              <DateRangePicker onChange={dateRangeChange} isOpen={isOpen} />
            </Col>
            <Col>
              {' '}
              <h1 className="text-heading">{startDate ? `Complaints from ${startDate} to ${endDate}` : ''}</h1>
            </Col>
            <Col offset={{ md: 8 }} breakPoint={{ xs: 12, md: 4 }}>
              <InputGroup fullWidth size="Small" status="Info">
                <input
                  type="text"
                  placeholder="Search..."
                  onChange={(event) => handleInputChange(event)}
                  onKeyDown={(event) => handleKeyDown(event)}
                />
              </InputGroup>
            </Col>
          </Row>
          <DataTable
            title="Complaint List"
            columns={tableColumns}
            data={rows}
            pagination
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
            paginationPerPage={100}
            striped
            dense
            noHeader
            highlightOnHover
          />
        </div>
      </div>
    </>
  );

  return <>{isLoading ? <LoadingSpinner message="Complaints" /> : tableDiv}</>;
}
